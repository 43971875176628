import _ from "lodash";
import { InteractionType, LivechatSessionType, LivechatState, MessageStatus } from "./types";
import moment from "moment";

export function handleMessageSendingError(
  state: any,
  interactionId: string,
  updatedLivechat: LivechatSessionType,
  commit: any
) {
  // Get latest chat cuz chat can be changed before this catch
  const updatingChat = _.find(state.queueChatObject, (chat) => {
    return (
      chat.user_id === updatedLivechat.user_id && chat.PartitionKey === updatedLivechat.PartitionKey
    );
  });
  const updatingChatInteractions: InteractionType[] = _.cloneDeep(
    _.get(updatingChat, "interactions", [])
  );

  const failedSendingInteraction = _.find(updatingChatInteractions, (interaction) => {
    return interaction.RowKey === interactionId;
  });
  if (failedSendingInteraction) {
    failedSendingInteraction.status = MessageStatus.ERROR;
  }
  updatedLivechat.interactions = updatingChatInteractions;
  commit("UPDATE_QUEUE_CHAT", { updatedLivechat });
}

export function isConnectionAvailable(state: any, isOnline: boolean) {
  const socketConnectionStatus = _.get(state, "socketConnection", "");
  return socketConnectionStatus === "connected" && isOnline;
}

export function mergeLatestQueueChatObject(
  currentChatObject: LivechatState["queueChatObject"],
  newChatObject: LivechatState["queueChatObject"]
): LivechatState["queueChatObject"] {
  // Ensure that the data fetched from /unresolvedSessions does not override the existing data unless it is more recent
  const newQueueChatObject = _.cloneDeep(currentChatObject);

  _.forEach(newChatObject, (payloadObject, key) => {
    const stateChatObject = currentChatObject?.[key];
    if (stateChatObject?.isRemoved) return; // if chat already resolved, do not add to queue again

    const stateLastModified = stateChatObject?.last_modified
      ? moment(stateChatObject?.last_modified)
      : null;
    const payloadLastModified = payloadObject?.last_modified
      ? moment(payloadObject?.last_modified)
      : null;

    if (
      !stateChatObject ||
      (stateLastModified && payloadLastModified && stateLastModified.isBefore(payloadLastModified))
    ) {
      newQueueChatObject[key] = payloadObject;
    }
  });

  return newQueueChatObject;
}

export function cleanQueueChatObject(
  chatObject: LivechatState["queueChatObject"],
  newChatObject: LivechatState["queueChatObject"]
): LivechatState["queueChatObject"] {
  const clonedChatObject = _.cloneDeep(chatObject);

  _.forEach(clonedChatObject, (chat, key) => {
    if (chat.isRemoved && !newChatObject[key]) {
      delete clonedChatObject[key];
    }
  });

  return clonedChatObject;
}
